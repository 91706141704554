import useGetList from "../data/useGetList";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

// dayjs 플러그인 설정
dayjs.extend(customParseFormat);
dayjs.extend(utc);

const useHourlyReportHook = (id, unit, from, to) => {
  const endpoint = `plant/hyrogen/inverter/${id}/hourlyReport`;

  const params = {
    unit: unit,
    from: dayjs(from).format("YYYY-MM-DD"),
    to: dayjs(to).format("YYYY-MM-DD"),
  };
  console.log(params);

  const { data, isLoading, error } = useGetList({ endpoint, params });
  console.log("useHourlyReportHook data: ", data);

  return { data, isLoading, error };
};

export default useHourlyReportHook;
