import React from "react";
import useH2DataHook from "../hooks/service/useH2DataHook";
import { Box, CircularProgress, Stack } from "@mui/material";
import DynamicMuiTable from "../components/common/DynamicMuiTable";

const tableHeight = 700;
const fieldOrder = [
  { key: "h2", label: "수소 측정값" },
  { key: "temp", label: "온도" },
  { key: "humidity", label: "습도" },
  { key: "created_at", label: "측정 시간" },
];

const H2DataList = () => {
  const { data, isLoading } = useH2DataHook();
  console.log("data: ", data);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: tableHeight,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <DynamicMuiTable data={data.data} fieldOrder={fieldOrder} />
    </Stack>
  );
};

export default H2DataList;
