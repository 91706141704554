import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Logs from "./pages/Logs";
import Chart from "./pages/Chart";
import DashBoard from "./pages/DashBoard";
import User from "./pages/User";
import PageContainer from "./components/common/PageContainer";
import H2DataList from "./pages/H2DataList";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="login" />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/*"
          element={
            <PageContainer>
              <Routes>
                <Route path="/dashboard" element={<DashBoard />}></Route>
                <Route path="/chart" element={<Chart />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/user" element={<User />} />
                <Route path="/h2" element={<H2DataList />} />
              </Routes>
            </PageContainer>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
