import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React from 'react';

const DynamicRadioButton = ({ title, options, selected, setSelected, sx }) => {
  // 라디오 버튼 선택 변경 핸들러
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl component="fieldset" sx={sx}>
      <FormLabel component="legend">{title}</FormLabel>
      <RadioGroup
        sx={{ display: 'flex', flexDirection: 'row' }}
        value={selected}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default DynamicRadioButton;
