import { useMutation } from '@tanstack/react-query';
import { useDataProvider } from '../../providers/dataProvider';

const useDeleteItem = () => {
  const dataProvider = useDataProvider();

  const deleteItem = useMutation({
    mutationFn: (props) =>
      dataProvider.delete({ endpoint: props.endpoint, params: props.params }),
  });

  return deleteItem;
};

export default useDeleteItem;
