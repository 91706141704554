import { Box, Button, Menu, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const DropdownMenu = ({ title, setState, menu = [], sx }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (e) => {
    setState(e.currentTarget.textContent);
    setAnchorEl(null);
  };

  return (
    <Box sx={{ mb: '20px', ...sx }}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
        startIcon={<ArrowDropDownIcon />}
      >
        {title}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!isEmpty(menu) &&
          menu.map((item, idx) => (
            <MenuItem key={idx} onClick={handleMenuClick}>
              {item}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default DropdownMenu;
