import React from "react";
import { Box } from "@mui/material";

const DashBoard = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      대시보드가 들어갈 페이지입니다.
    </Box>
  );
};

export default DashBoard;
