import useGetList from "../data/useGetList";

const useUserHook = () => {
  const endpoint = "user";

  const { data, isLoading } = useGetList({ endpoint });
  // console.log('useUserHook data: ', data);

  return { data, isLoading };
};

export default useUserHook;
