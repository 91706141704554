import { Box, Button, DialogActions, Stack } from '@mui/material';
import CustomTextField from '../common/CustomTextField';
import { useState } from 'react';
import { isEmpty } from 'lodash';

/**
 *
 * @param {Object} props
 * @param {Object} props.form - 폼에 입력된 정보
 * @param {React.SetStateAction<formData>} props.setForm - 폼 입력 함수
 * @param {Function} handleClose
 * @param {Function} handleClickSubmit
 * @returns
 */

const UserForm = (props) => {
  const {
    form,
    setForm,
    handleClose,
    handleClickAction,
    isEditMode = false,
  } = props;
  const [checkPassword, setCheckPassword] = useState('');
  // console.log(checkPassword);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCheckPasswordChange = (event) => {
    setCheckPassword(event.target.value);
  };

  return (
    <Stack>
      <CustomTextField
        name={'id'}
        title={'아이디'}
        hint={'아이디를 입력해주세요.'}
        onChange={handleChange}
        disabled={isEditMode}
        value={form.id}
      />
      {isEditMode ? (
        <Box>
          <CustomTextField
            name={'password'}
            type={'password'}
            title={'새로운 비밀번호'}
            hint={'변경할 비밀번호를 입력해주세요.'}
            onChange={handleChange}
          />
          <CustomTextField
            name={'checkPassword'}
            type={'password'}
            title={'새로운 비밀번호 확인'}
            hint={'변경할 비밀번호를 다시 입력해주세요.'}
            errorText={
              !isEmpty(checkPassword) &&
              form.password !== checkPassword &&
              '비밀번호가 일치하지 않습니다.'
            }
            onChange={handleCheckPasswordChange}
          />
        </Box>
      ) : (
        <Box>
          <CustomTextField
            name={'password'}
            type={'password'}
            title={'비밀번호'}
            hint={'비밀번호를 입력해주세요.'}
            onChange={handleChange}
          />
          <CustomTextField
            name={'checkPassword'}
            type={'password'}
            title={'비밀번호 확인'}
            hint={'비밀번호를 다시 입력해주세요.'}
            errorText={
              !isEmpty(checkPassword) &&
              form.password !== checkPassword &&
              '비밀번호가 일치하지 않습니다.'
            }
            onChange={handleCheckPasswordChange}
          />
        </Box>
      )}
      <CustomTextField
        name={'role'}
        title={'역할'}
        hint={'역할을 입력해주세요.'}
        onChange={handleChange}
        value={form.role}
      />
      <CustomTextField
        name={'name'}
        title={'이름'}
        hint={'이름을 입력해주세요.'}
        onChange={handleChange}
        value={form.name}
      />
      <CustomTextField
        name={'email'}
        type={'email'}
        title={'이메일'}
        hint={'이메일을 입력해주세요.'}
        onChange={handleChange}
        value={form.email}
      />

      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          취소
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleClickAction}
          // disabled
        >
          {isEditMode ? '수정하기' : '등록하기'}
        </Button>
      </DialogActions>
    </Stack>
  );
};

export default UserForm;
