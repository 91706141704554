import React, { useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const SideMenuBar = (props) => {
  const { drawerWidth, menuItems } = props;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <Box
    // role="presentation"
    // onClick={toggleDrawer(false)}
    // onKeyDown={toggleDrawer(false)}
    // sx={{ mt: '50px', width: '250px' }}
    >
      <List>
        {menuItems.map((item, idx) => (
          <ListItem
            key={idx}
            onClick={() => navigate(item.path)}
            sx={{
              cursor: "pointer",
              "&:hover": { backgroundColor: "lightgray" },
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        // open={open}
        // onClose={toggleDrawer(false)}
      >
        <Toolbar />
        {list()}
      </Drawer>
    </Box>
  );
};

export default SideMenuBar;
