import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

/**
 *
 * @param {Object} props
 * @param {string} props.title - Dialog Title
 * @param {string} [props.subTitle] - Dialog subTitle
 * @param {boolean} props.open - Dialog subTitle
 * @param {Function} props.onClose - Dialog Title
 * @param {Function} [props.onDo] - Dialog Title
 * @param {string} [props.closeMsg] - Dialog Title
 * @param {string} [props.doMsg] - Dialog Title
 * @param {React.ComponentType} [props.content] - Dialog Title
 *
 * @returns {JSX.Element}
 */
const HwBasicDialog = (props) => {
  const {
    title,
    subTitle,
    open,
    onClose,
    onDo,
    closeMsg = '취소',
    doMsg = '실행',
    content,
  } = props;
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {content ? (
          content
        ) : (
          <Stack>
            <Typography>{subTitle}</Typography>
            <Stack direction={'row'} alignSelf={'end'}>
              <Button onClick={onClose}>{closeMsg}</Button>
              <Button onClick={onDo}>{doMsg}</Button>
            </Stack>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default HwBasicDialog;
