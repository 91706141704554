import {
  Box,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';

/**
 * 커스텀 텍스트필드.
 *
 * @param {string} title - 텍스트필드의 label
 * @param {string} hint - 텍스트필드의 placeholder
 * @param {string} name - 텍스트필드의 name (식별자 용도)
 * @param {string} type - 텍스트필드의 type (password로 설정시 문자 가리기)
 * @param {CallableFunction} onChange - 텍스트필드 입력 콜백

 */
const CustomTextField = (props) => {
  const {
    title,
    hint,
    name,
    type,
    value,
    onChange,
    disabled,
    helperText,
    errorText,
  } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{title}</Typography>
      <FormControl fullWidth sx={{ mt: '8px' }} error={!!errorText}>
        <TextField
          name={name}
          type={type}
          value={value}
          placeholder={hint}
          onChange={onChange}
          disabled={disabled}
        />
        <FormHelperText>{errorText || helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default CustomTextField;
