import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import CustomTextField from '../components/common/CustomTextField';
import { valid } from '../utils/validator';
import { useNavigate } from 'react-router-dom';
import CustomAppBar from '../components/common/CustomAppBar';
import { dataProvider } from '../providers/dataProvider';

const Login = () => {
  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({ id: '', password: '' });
  console.log(form);
  const [errorText, setErrorText] = useState('');
  const [loginError, setLoginError] = useState('');
  console.log(loginError);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleBtnClick = async (e) => {
    e.preventDefault();
    try {
      const response = await dataProvider.create({
        endpoint: 'signin',
        body: form,
      });
      console.log(response);
      if (response.status === 'success') {
        setOpen(false);
        localStorage.setItem('token', response.token);
        navigate('/dashboard');
      }
    } catch (err) {
      console.error(err);
      setForm({ id: '', password: '' });
      setLoginError('아이디 혹은 비밀번호가 일치하지 않습니다.');
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem('token') ? true : false;
    if (isLoggedIn) navigate('/dashboard');
  }, []);

  return (
    <div>
      <CustomAppBar />
      <Dialog open={open}>
        <Box sx={{ px: '70px' }}>
          <DialogTitle
            sx={{ display: 'flex', justifyContent: 'center', mt: '40px' }}
          >
            <Typography sx={{ fontSize: 24, fontWeight: 700 }}>
              관리자 로그인
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ width: '376px' }}>
            <Box sx={{ mb: '16px' }}>
              <CustomTextField
                name={'id'}
                title={'아이디'}
                hint={'아이디를 입력해주세요.'}
                onChange={handleChange}
                value={form.id}
                // helperText={form.id && form.id.length < 5 ? '5글자 이상' : ''}
                // errorText={errorText}
              />
            </Box>
            <Box sx={{ mb: '16px' }}>
              <CustomTextField
                name={'password'}
                type={'password'}
                title={'비밀번호'}
                hint={'비밀번호를 입력해주세요.'}
                onChange={handleChange}
                value={form.password}
              />
            </Box>
            <Button
              variant="contain"
              sx={{
                backgroundColor: form.id && form.password ? 'gray' : '#F4F4F4',
                width: '100%',
                height: '54px',
                mt: '16px',
                '&:hover': { backgroundColor: 'lightgray' },
              }}
              // disabled={true}
              onClick={handleBtnClick}
            >
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  color: form.id && form.password ? 'white' : '#C1C2C2',
                }}
              >
                로그인
              </Typography>
            </Button>
            {loginError && (
              <Typography
                color="error"
                sx={{
                  textAlign: 'center',
                  fontSize: '14px',
                  mt: '12px',
                  mb: '40px',
                }}
              >
                {loginError}
              </Typography>
            )}
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
};

export default Login;
