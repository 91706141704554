import React, { useState } from "react";
import useLogsHook from "../hooks/service/useLogsHook";
import DropdownMenu from "../components/common/DropdownMenu";
import { inverters } from "../config";
import DynamicMuiTable from "../components/common/DynamicMuiTable";
import { Box, CircularProgress } from "@mui/material";

const tableHeight = 700;
const fieldOrder = [
  { key: "input_volt", label: "입력 전압" },
  { key: "input_current", label: "입력 전류" },
  { key: "input_power", label: "입력 전력" },
  { key: "output_volt(r,s,t)", label: "출력 전압(R,S,T)" },
  { key: "output_current(r,s,t)", label: "출력 전류(R,S,T)" },
  { key: "output_power", label: "출력 전력" },
  { key: "output_frequency", label: "출력 주파수" },
  { key: "output_power_factor", label: "출력 역률" },
  { key: "created_at", label: "측정 시간" },
];

const Logs = () => {
  const [inverterId, setInverterId] = useState(inverters[0]);
  console.log(inverterId);
  const [limit, setLimit] = useState(50);

  const { data, isLoading } = useLogsHook(inverterId, limit);
  console.log(data);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        width={"95%"}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <DropdownMenu
          title={`인버터 ID: ${inverterId}`}
          setState={setInverterId}
          menu={inverters}
        />
        <DropdownMenu
          title={limit}
          setState={setLimit}
          menu={[10, 20, 30, 50]}
        />
      </Box>
      {isLoading && (
        <Box
          sx={{ display: "flex", alignItems: "center", height: tableHeight }}
        >
          <CircularProgress />
        </Box>
      )}
      <DynamicMuiTable
        data={data}
        sx={{ height: tableHeight }}
        fieldOrder={fieldOrder}
      />
    </Box>
  );
};

export default Logs;
