import React from "react";
import { Stack } from "@mui/material";
import CustomAppBar from "./CustomAppBar";
import SideMenuBar from "./SideMenuBar";

import {
  List as ListIcon,
  BarChart as BarChartIcon,
  PeopleAlt as MembersIcon,
  PhoneIphone as PhoneIphoneIcon,
} from "@mui/icons-material";

const drawerWidth = 240;

const menuItems = [
  {
    text: "로그 테이블",
    icon: <ListIcon />,
    path: "/logs",
  },
  { text: "월간 그래프", icon: <BarChartIcon />, path: "/chart" },
  { text: "회원 관리", icon: <MembersIcon />, path: "/user" },
  { text: "앱 데이터 관리", icon: <PhoneIphoneIcon />, path: "/h2" },
];

const PageContainer = (props) => {
  return (
    <Stack
      sx={{
        minHeight: "100vh",
      }}
    >
      <CustomAppBar />
      <SideMenuBar drawerWidth={drawerWidth} menuItems={menuItems} />
      <Stack sx={{ p: 3, marginLeft: `${drawerWidth}px` }}>
        {props.children}
      </Stack>
    </Stack>
  );
};

export default PageContainer;
