import { useMutation } from '@tanstack/react-query';
import { useDataProvider } from '../../providers/dataProvider';

const useUpdateItem = () => {
  const dataProvider = useDataProvider();

  const updateItem = useMutation({
    mutationFn: (props) => {
      const { endpoint, body } = props;
      return dataProvider.update({ endpoint, body });
    },
  });

  return updateItem;
};

export default useUpdateItem;
