import { AppBar, Box, Button, Toolbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ImageString } from '../../images';
import { useNavigate } from 'react-router-dom';

const CustomAppBar = (props) => {
  const { position = 'sticky', sx, ...others } = props;
  const navigate = useNavigate();

  // appbar 배경 컬러변경
  const [isScroll, setIsScroll] = useState(false);

  const isLoggedIn = localStorage.getItem('token') ? true : false;

  const handleLogoutClick = () => {
    localStorage.removeItem('token');
    navigate('/login', { replace: true }); // 로그인 페이지로 이동 (replace를 사용해 히스토리 교체)
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScroll(true);
      } else if (window.scrollY === 0) {
        setIsScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar // position={pathname === '/' ? position : 'sticky'}
      position={position}
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        top: 0,
        background: ' #ffffff',
        borderBottom: '1px solid rgba(0, 10, 16, 0.1)',
        ...sx,
        // pathname === '/'
        //   ? isScroll
        //     ? color?.confin_indigo
        //     : 'transparent'
        //   : color?.confin_indigo,
        opacity: isScroll ? 0.9 : 1,
        transition: 'background 0.3s',
        // borderBottom:
        //   pathname === '/' ? 'none' : `1px solid ${color?.confin_gray_02}`,
      }}
      {...others}
    >
      <Toolbar>
        <Box
          component="img"
          src={ImageString.LogoHorizon}
          sx={{ height: '50px', p: '8px', cursor: 'pointer' }}
          onClick={() => navigate('/')}
        />
        <Box
          sx={{
            display: 'flex', // 수평으로 배치
            flexDirection: 'row', // 수평 방향 유지
            alignItems: 'center', // 수직 가운데 정렬
            justifyContent: 'flex-end', // 오른쪽 정렬
            flexGrow: 1, // 버튼 그룹이 오른쪽 끝으로 밀리도록 설정
          }}
        >
          {isLoggedIn && (
            <Button
              onClick={handleLogoutClick}
              variant="outlined"
              sx={{ marginLeft: '8px' }}
            >
              로그아웃
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
