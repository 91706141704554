import { Box } from '@mui/material';
import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  Line,
  ComposedChart,
} from 'recharts';

const SimpleChart = ({ data, unit, height }) => {
  console.log(data);

  const CustomLegend = (value, entry, index) => {
    const { color } = entry;
    // 원하는 텍스트로 커스터마이징
    const customLabels = {
      amount: '발전량',
      price: '발전금액',
    };

    return (
      <Box sx={{ color, mb: '30px' }}>
        {customLabels[value] || value} {/* 커스텀된 텍스트로 출력 */}
      </Box>
    );
  };

  return (
    <ResponsiveContainer width="90%" height={height}>
      <ComposedChart data={data} margin={{ left: 30, right: 30 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tickFormatter={(value) => {
            if (unit === 'daily') {
              return `${String(value).substring(0, 4)}-${String(
                value
              ).substring(4, 6)}-${String(value).slice(-2)}`;
            } else {
              return `${String(value).substring(0, 4)}-${String(value).slice(
                -2
              )}`;
            }
          }}
        />
        <YAxis
          yAxisId="left"
          axisLine={false}
          tickMargin={10}
          tickFormatter={(value) => `${value}kWh`}
          // domain={['auto', 'dataMax + 1000']}
        />
        <YAxis
          yAxisId="right"
          orientation="right"
          axisLine={false}
          tickMargin={10}
          tickFormatter={(value) => `${value}원`}
          // domain={['auto', 'dataMax + 50000']}
        />
        <Tooltip />
        <Legend
          layout="horizontal" // 수평 레이아웃
          align="right"
          verticalAlign="top"
          formatter={CustomLegend}
        />
        <Bar dataKey="amount" fill="#8884d8" yAxisId="left" />
        <Line
          type="monotone"
          strokeWidth="3"
          dataKey="price"
          stroke="#eeaabb"
          yAxisId="right"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default SimpleChart;
