import { CssBaseline } from "@mui/material";
import { Router } from "./Router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DataProvider, { dataProvider } from "./providers/dataProvider";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DataProvider dataProvider={dataProvider}>
          <CssBaseline />
          <Router />
          <ReactQueryDevtools initialIsOpen={false} />
        </DataProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
