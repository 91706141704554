import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { isEmpty } from "lodash";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import React from "react";

// dayjs 플러그인 설정
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);

const DynamicMuiTable = (props) => {
  const { data, clickUpdate, clickDelete, action, fieldOrder, sx } = props;
  console.log(data);

  if (isEmpty(data)) {
    return;
  }

  const isValidDate = (dateString) => {
    if (!dateString || typeof dateString !== "string") {
      return false;
    }

    // ISO 형식의 날짜 문자열인지 확인
    const hasValidFormat = String(dateString).includes("T");
    if (!hasValidFormat) {
      return false;
    }

    // dayjs로 유효한 날짜인지 확인
    const date = dayjs(dateString);
    return date.isValid();
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format("YYYY-MM-DD HH:mm:ss.SSS");
  };

  const normalizedData = data.map((row) => {
    const normalizedRow = {};
    fieldOrder.forEach(({ key }) => {
      normalizedRow[key] = row[key] || "";
    });
    return normalizedRow;
  });
  console.log("DynamicMuiTable normalizedData: ", normalizedData);

  return (
    <Paper sx={{ width: "100%", overflow: "auto", ...sx }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {fieldOrder
                .filter(({ key }) => key !== "deleted")
                .map(({ key, label }, idx) => (
                  <TableCell key={idx} sx={{ padding: "8px", lineHeight: 1.5 }}>
                    <TableSortLabel>
                      {label || key.split("_").join(" ").toUpperCase()}
                    </TableSortLabel>
                  </TableCell>
                ))}
              {action && <TableCell align="center"></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {normalizedData
              .map(({ deleted, ...rest }) => rest)
              .map((item, idx) => (
                <TableRow key={idx}>
                  {fieldOrder.map(({ key }, idx) => (
                    <TableCell key={idx} sx={{ padding: "8px", lineHeight: 1 }}>
                      {isValidDate(item[key])
                        ? formatDate(item[key])
                        : item[key]}
                    </TableCell>
                  ))}
                  {action && (
                    <TableCell align="center">
                      <Button onClick={() => clickUpdate(item)}>수정</Button>
                      <Button onClick={() => clickDelete(item)}>삭제</Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 20, 30]} // 선택 가능한 페이지 당 행 수 옵션
        component="div"
        count={data.length} // 전체 데이터 개수
        rowsPerPage={rowsPerPage} // 페이지 당 행 수
        page={page} // 현재 페이지
        onPageChange={handleChangePage} // 페이지 변경 핸들러
        onRowsPerPageChange={handleChangeRowsPerPage} // 페이지 당 행 수 변경 핸들러
      /> */}
    </Paper>
  );
};

export default DynamicMuiTable;
