import React, { useState } from "react";
import { inverters, unitOptions } from "../config";
import useHourlyReportHook from "../hooks/service/useHourlyReportHook";
import SimpleChart from "../components/common/SimpleChart";
import { Box, CircularProgress, Toolbar } from "@mui/material";
import DropdownMenu from "../components/common/DropdownMenu";
import DynamicRadioButton from "../components/common/DynamicRadioButton";
import CustomDatePicker from "../components/common/CustomDatePicker";

const chartHeight = 500;

const Chart = () => {
  const [inverterId, setInverterId] = useState(inverters[0]);
  // console.log(inverterId);
  const [unit, setUnit] = useState(unitOptions[0].value);

  const [fromDate, setFromDate] = useState(null);
  // console.log(fromDate);
  const [toDate, setToDate] = useState(null);
  // console.log(toDate);

  const { data, isLoading } = useHourlyReportHook(
    inverterId,
    unit,
    fromDate,
    toDate
  );
  console.log(data);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box width={"95%"}>
        <DropdownMenu
          sx={{ alignSelf: "flex-start" }}
          title={`인버터 ID: ${inverterId}`}
          setState={setInverterId}
          menu={inverters}
        />
      </Box>
      <Toolbar
        sx={{
          display: "flex",
          // justifyContent: 'space-between',
          width: "95%",
          mb: "30px",
          border: "1px solid black", // 테두리 추가
          borderRadius: 2, // 모서리 둥글게
          padding: 2, // 패딩 추가
          gap: 2,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <CustomDatePicker
            label="시작일"
            value={fromDate}
            setValue={setFromDate}
          />
          <CustomDatePicker
            label="종료일"
            value={toDate}
            setValue={setToDate}
          />
        </Box>
        <DynamicRadioButton
          options={unitOptions}
          selected={unit}
          setSelected={setUnit}
          sx={{ ml: "15px" }}
        />
      </Toolbar>
      {isLoading && (
        <Box
          sx={{
            height: chartHeight,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {data ? (
        <SimpleChart data={data} unit={unit} height={chartHeight} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Chart;
