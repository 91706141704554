import { useQuery } from "@tanstack/react-query";
import { useDataProvider } from "../../providers/dataProvider";

const useGetList = ({ endpoint, params }) => {
  const dataProvider = useDataProvider();

  const res = useQuery({
    queryKey: [endpoint, params],
    queryFn: () => dataProvider.getList({ endpoint, params }),
  });

  return res;
};

export default useGetList;
