import axios from 'axios';
import { baseUrl } from '../../config/aws';

const apiClient = axios.create({ baseURL: baseUrl });

export const apiManager = async ({
  method = 'get',
  endpoint,
  body,
  params,
}) => {
  console.log('api manager input: ', { method, endpoint, params, body });
  const response = await apiClient({
    method,
    url: endpoint,
    data: body,
    params,
  });
  console.log('api manager response: ', response);

  return response.data;
};
