import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const apiClient = axios.create({
  baseURL: "https://dev2025.api.lessontalk.co.kr",
});

const apiManager = async ({ method = "get", endpoint, body, params }) => {
  const response = await apiClient({
    method,
    url: endpoint,
    data: body,
    params,
  });
  return response.data;
};

const refetchInterval = 500;

const useH2DataHook = () => {
  const endpoint = `hyrogen/h2`;

  const { data, isLoading } = useQuery({
    queryKey: [endpoint],
    queryFn: () => apiManager({ endpoint }),
    refetchInterval: refetchInterval,
    refetchIntervalInBackground: true,
    staleTime: 0,
  });

  return { data, isLoading };
};

export default useH2DataHook;
