import { useMutation } from '@tanstack/react-query';
import { useDataProvider } from '../../providers/dataProvider';

const usePostItem = () => {
  const dataProvider = useDataProvider();

  const postItem = useMutation({
    mutationFn: (props) =>
      dataProvider.create({ endpoint: props.endpoint, body: props.body }),
  });

  return postItem;
};

export default usePostItem;
