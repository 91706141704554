import useGetList from "../data/useGetList";

const useLogsHook = (id, limit) => {
  const endpoint = `plant/hyrogen/inverter/${id}/log`;
  let lastKey;
  const params = {
    limit: limit,
    ...(lastKey && String(lastKey).includes(id) && { lastKey }),
  };

  const { data, isLoading, error } = useGetList({ endpoint, params });

  const logsResponse = (logs) => {
    return logs?.map((log) => ({
      id: log.rtu_id,
      input_volt: log.input_volt,
      input_current: log.input_current,
      input_power: log.input_power,
      "output_volt(r,s,t)": `${log.output_volt_r}, ${log.output_volt_s}, ${log.output_volt_t}`,
      "output_current(r,s,t)": `${log.output_current_r}, ${log.output_current_s}, ${log.output_current_t}`,
      output_power: log.output_power,
      output_frequency: log.output_frequency,
      output_power_factor: log.output_power_factor,
      status_value: log.status_value,
      created_at: log.created_at,
    }));
  };

  return {
    data: logsResponse(data?.logs),
    isLoading,
    error,
  };
};

export default useLogsHook;
