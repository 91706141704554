import { createContext, useContext } from 'react';
import { apiManager } from '../hooks/api/apiManager';

export const dataProvider = {
  getList: async ({ endpoint, params }) => {
    // console.log('dataprovider.getlist input: ', { endpoint, params });
    try {
      const response = await apiManager({ endpoint, params });

      return response;
    } catch (err) {
      console.error(err.message);
    }
  },
  getOne: () => Promise.resolve(),
  getMany: () => Promise.resolve(),
  update: async ({ endpoint, body }) => {
    // console.log('dataprovider.update input: ', { endpoint, params, body });
    try {
      const response = await apiManager({
        method: 'PUT',
        endpoint,
        body,
      });

      return response;
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  },
  updateMany: () => Promise.resolve(),
  create: async ({ endpoint, body }) => {
    // console.log('dataprovider.create input: ', { endpoint, body });
    try {
      const response = await apiManager({ method: 'POST', endpoint, body });

      return response;
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  },
  delete: async ({ endpoint, params }) => {
    // console.log('dataprovider.delete input: ', { endpoint, params });
    try {
      const response = await apiManager({ method: 'DELETE', endpoint, params });

      return response;
    } catch (err) {
      console.error(err);
      throw new Error();
    }
  },
  deleteMany: () => Promise.resolve(),
};

const DataContext = createContext(dataProvider);

export const useDataProvider = () => {
  const context = useContext(DataContext);

  return context;
};

const DataProvider = ({ children, dataProvider }) => {
  return (
    <DataContext.Provider value={dataProvider}>{children}</DataContext.Provider>
  );
};

export default DataProvider;
