import { DatePicker } from '@mui/x-date-pickers';
import React from 'react';

const CustomDatePicker = ({ label, width, value, setValue }) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={(newValue) => setValue(newValue)}
      slotProps={{
        textField: {
          sx: {
            width: width,
            '& .MuiInputBase-root': {
              height: '40px', // 높이 조정
            },
            '& .MuiInputLabel-root': {
              top: '-6px', // 라벨의 위치를 위로 조정
              fontSize: '0.85rem', // 라벨 크기 조정
            },
            '& .MuiInputLabel-shrink': {
              top: '0', // 라벨이 축소되었을 때 위치 조정
            },
          },
        },
      }}
    />
  );
};

export default CustomDatePicker;
