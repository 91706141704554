import React, { useState } from "react";
import { Button, Typography, CircularProgress, Stack } from "@mui/material";
import useUserHook from "../hooks/service/useUserHook";
import DynamicMuiTable from "../components/common/DynamicMuiTable";
import UserForm from "../components/user/UserForm";
import usePostItem from "../hooks/data/usePostItem";
import { useQueryClient } from "@tanstack/react-query";
import HwBasicDialog from "../components/common/HwBasicDialog";
import useDeleteItem from "../hooks/data/useDeleteItem";
import useUpdateItem from "../hooks/data/useUpdateItem";

const tableHeight = 700;

const dialogState = Object.freeze({
  none: "NONE",
  form: "FORM",
  update: "UPDATE",
  delete: "DELETE",
});

const formInitialValue = {
  id: "",
  password: "",
  role: "",
  name: "",
  email: "",
};

const fieldOrder = [
  { key: "role", label: "권한" },
  { key: "id", label: "아이디" },
  { key: "name", label: "이름" },
  { key: "email", label: "이메일" },
];

const User = () => {
  const [dialog, setDialog] = useState(dialogState.none);
  // console.log('User dialog status: ', dialog);
  const [formData, setFormData] = useState(formInitialValue);
  // console.log('User form data: ', formData);
  const [deleteParams, setDeleteParams] = useState({});
  // console.log('User delete params: ', deleteParams);

  const { data, isLoading } = useUserHook();
  // console.log('User data: ', data);

  const queryClient = useQueryClient();
  const postItem = usePostItem();
  const updateItem = useUpdateItem();
  const deleteItem = useDeleteItem();

  const handleClickUserRegist = () => {
    setDialog(dialogState.form);
  };

  const handleClose = (e, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setDialog(dialogState.none);
    setFormData(formInitialValue);
  };

  const handleClickSubmit = () => {
    postItem.mutate(
      { endpoint: "user", body: formData },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["user"]);
          setDialog(dialogState.none);
          setFormData(formInitialValue);
        },
      }
    );
  };

  const handleClickUpdate = () => {
    const { id: userId, ...rest } = formData;
    updateItem.mutate(
      { endpoint: `user/${userId}`, body: rest },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([`user`]);
          setDialog(dialogState.none);
          setFormData(formInitialValue);
        },
      }
    );
  };

  const clickItemUpdate = (item) => {
    // console.log('clickItemUpdate input: ', item);
    setFormData(item);
    setDialog(dialogState.update);
  };

  const clickItemDelete = (item) => {
    // console.log('clickItemDelete input: ', item);
    const { id, role } = item;
    setDeleteParams({ userId: id, role: role });
    setDialog(dialogState.delete);
  };

  const handleItemDelete = () => {
    deleteItem.mutate(
      { endpoint: "user", params: deleteParams },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["user"]);
          setDialog(dialogState.none);
        },
      }
    );
  };

  return (
    <>
      <Stack sx={{ mb: "25px", alignSelf: "start" }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "gray",
            "&:hover": { backgroundColor: "lightgray" },
          }}
          onClick={handleClickUserRegist}
        >
          <Typography>회원 등록</Typography>
        </Button>
      </Stack>
      {isLoading && (
        <Stack
          sx={{
            alignSelf: "center",
            justifyContent: "center",
            height: tableHeight,
          }}
        >
          <CircularProgress />
        </Stack>
      )}
      <DynamicMuiTable
        data={data}
        clickUpdate={clickItemUpdate}
        clickDelete={clickItemDelete}
        fieldOrder={fieldOrder}
        sx={{ height: tableHeight }}
        action
      />
      ;{/** 회원 등록 팝업 */}
      <HwBasicDialog
        title="회원 등록"
        open={dialog === dialogState.form ? true : false}
        onClose={handleClose}
        content={
          <UserForm
            form={formData}
            setForm={setFormData}
            handleClose={handleClose}
            handleClickAction={handleClickSubmit}
          />
        }
      ></HwBasicDialog>
      {/** 회원 정보 수정 팝업 */}
      <HwBasicDialog
        title="회원 정보 수정"
        open={dialog === dialogState.update ? true : false}
        onClose={handleClose}
        content={
          <UserForm
            form={formData}
            setForm={setFormData}
            handleClose={handleClose}
            isEditMode={true}
            handleClickAction={handleClickUpdate}
          ></UserForm>
        }
      ></HwBasicDialog>
      {/** 회원 삭제 팝업 */}
      <HwBasicDialog
        title="유저를 삭제하시겠습니까?"
        open={dialog === dialogState.delete ? true : false}
        onClose={handleClose}
        onDo={handleItemDelete}
        doMsg="삭제"
      ></HwBasicDialog>
    </>
  );
};

export default User;
